import { DefaultValues } from 'helpers/defaultsUtils'

export const list = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']).replace(/\D/gi, ''),
		amount: model.null<number>(['amount']),
		durationMonths: model.null<number>(['durationMonths']),
		annualProfitPercentage: model.null<number>(['annualProfitPercentage']),
		upcomingPayment: data?.upcomingPayment
			? {
					amount: model.null<number>(['upcomingPayment', 'amount']),
					date: model.string(['upcomingPayment', 'date']),
				}
			: null,
		status: {
			id: model.string(['status', 'id']),
			title: model.string(['status', 'title']),
		},
		paymentType: {
			id: model.string(['paymentType', 'id']),
			title: model.string(['paymentType', 'title']),
		},
		$$component: 'saving' as const,
	}
}
