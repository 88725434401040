import { type ErrorInfo, Component } from 'react'
import { ErrorLayout } from 'components/Layouts'

export class ErrorBoundary extends Component {
	state = {
		error: false,
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		console.error({ error, errorInfo })
		this.setState({ error: true })
	}

	render() {
		const { children } = this.props
		const { error } = this.state

		return <>{error ? <ErrorLayout /> : children}</>
	}
}
