import { DefaultValues } from 'helpers/defaultsUtils'

const option = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		value: model.string(['value']),
	}
}

export const saving = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		header: model.string(['header']),
		minAmount: model.null<number>(['minAmount']),
		maxAmount: model.null<number>(['maxAmount']),
		productDetailsUrl: model.string(['productDetailsUrl']),
		paymentTypeOptions: model.array(['paymentTypeOptions']).map(option),
		durationOptions: model.array(['durationOptions']).map(option),
	}
}
