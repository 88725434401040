import { app } from './app'
import { bankAccount } from './bankAccount'
import { document } from './document'
import { loan } from './loan'
import { productsShowcase } from './productsShowcase'
import { profile } from './profile'
import { saving } from './saving'
import { support } from './support'
import { shares } from './shares'

export default {
	profile,
	productsShowcase,
	document,
	shares,
	support,
	bankAccount,
	loan,
	saving,
	app,
}
