import { DefaultValues } from 'helpers/defaultsUtils'

export const moneyMovement = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		iconUrl: model.string(['iconUrl']),
		title: model.string(['title']),
		date: model.string(['date']),
		direction: model.const(data.direction, { OUTCOME: 'OUTCOME', INCOME: 'INCOME' } as const),
		amount: model.null<number>(['amount']),
	}
}
