export const urls = {
	main: {
		path: '/',
		title: 'Главная',
	},
	profile: {
		path: '/profile',
		title: 'Профиль',
	},
	docs: {
		path: '/docs',
		title: 'Документы',
	},
	chat: {
		path: '/chat',
		title: 'Чат',
	},
	share: {
		path: (id = ':id') => `/shares/${id}`,
		title: 'Паевые взносы',
	},
	loan: {
		path: (id = ':id') => `/loans/${id}`,
		title: 'Кредит',
	},
	bankAccount: {
		path: (id = ':id') => `/bank-account/${id}`,
		title: 'Счёт',
	},
	newSaving: {
		title: 'Сбережение',
		path: '/products-showcase/saving',
	},
	newLoan: {
		title: 'Кредит',
		path: '/products-showcase/loan',
	},
	productsShowcase: {
		path: '/products-showcase',
		title: 'Выберите продукт',
	},
	savingSteps: {
		path: (id = ':id') => `/savings/${id}/steps`,
		title: 'Заявка на сбережение',
	},
	saving: {
		path: (id = ':id') => `/savings/${id}`,
		title: 'Сбережения',
	},
	onboarding: {
		path: '/onboarding',
		title: 'Главная',
	},
	agreement: {
		path: '/agreement',
		title: 'Согласие на запрос КИ',
	},
	clientOrder: {
		path: (type = ':type', id = ':id') => `/client-order/${type.toLowerCase()}/${id}`,
		title: 'Заём',
	},
} as const satisfies Readonly<
	Record<string, { path: string | ((...args: string[]) => string); title: string }>
>
