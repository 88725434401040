import axios, { CancelToken } from 'axios'
import type { SharesDto } from 'converters/shares'
import { shares as sharesConverter } from 'converters/shares'

export namespace SharesApi {
	export type List = SharesDto.List[]
	export type Single = SharesDto.Single
}

export const shares = {
	search: (cancelToken?: CancelToken) =>
		axios
			.get<SharesApi.List>(`/v3/products/shares`, {
				cancelToken,
			})
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(sharesConverter.list) })
			),

	get: ({ id }: { id: string }, cancelToken?: CancelToken) =>
		axios.get<SharesApi.Single>(`/v3/products/shares/${id}`, { cancelToken }).then((response) =>
			Object.assign({}, response, {
				data: sharesConverter.single(response.data),
			})
		),

	getRequisites: ({ id }: { id: string }) =>
		axios.get(`/v3/products/shares/${id}/payment-requisites/deposit`),
}
