import { type PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { SharesDto } from 'converters/shares'
import { TRedux } from 'types'
import { EntityStatus } from 'types/redux'

import { commonReducers } from '../commonReducers'

const sharesListAdapter = createEntityAdapter<SharesDto.List>()

export const sharesListSelector = sharesListAdapter.getSelectors<TRedux.RootState>(
	(state) => state.shares.list
)

const initialState = sharesListAdapter.getInitialState({
	status: 'pending' as EntityStatus,
	isInitialized: false,
})

const listSlice = createSlice({
	name: 'shares/list',
	initialState,
	reducers: {
		setData: sharesListAdapter.setAll,

		setInitialized: (state, action: PayloadAction<boolean>) => {
			state.isInitialized = action.payload
		},

		setStatus: commonReducers.setStatus,
		reset: commonReducers.reset(initialState),
	},
})

const { actions: sharesListReducerActions, reducer } = listSlice

export { sharesListReducerActions, reducer as listReducer }
