import { DefaultValues } from 'helpers/defaultsUtils'

export const accountStats = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		referredBy: model.string(['referredBy']),
		referredEntries: model.array(['referredEntries']).map((_, i) => ({
			title: model.string(['referredEntries', i, 'title']),
			amount: model.null<number>(['referredEntries', i, 'amount']),
		})),
		totalReferralIncomeAmount: model.null<number>(['totalReferralIncomeAmount']),
		totalReferralsCount: model.null<number>(['totalReferralsCount']),
	}
}
