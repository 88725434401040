import api from 'api'
import { AxiosError } from 'axios'
import { onboardingReducerActions } from 'reducers/onboarding/main'
import { TAction } from 'types/redux'

// const mock = {
// 	id: '1',
// 	steps: [
// 		{
// 			title: 'Что такое Credit.Club?',
// 			imageUrl: 'https://dummyimage.com/327x327/000/0011ff&text=1',
// 			content: 'Предсказуемый финансовый сервис с прозрачными условиями.  Кредиты и сбережения.',
// 		},
// 		{
// 			title: 'Кредиты без похода в офис',
// 			imageUrl: 'https://dummyimage.com/327x327/000/0011ff&text=2',
// 			content:
// 				'Нужны деньги? Возьмите кредит на честных условиях без посещений офиса в 170 городах России.',
// 		},
// 		{
// 			title: 'Надёжные сбережения',
// 			imageUrl: 'https://dummyimage.com/327x327/000/0011ff&text=3',
// 			content: 'Сделайте сбережение и получайте регулярные выплаты с доходностью до 15% годовых.',
// 		},
// 	],
// }

export const onboardingActions = {
	get: (): TAction<Promise<void>> => async (dispatch) => {
		try {
			dispatch(onboardingReducerActions.setStatus('pending'))

			const { data } = await api.app.onboardings()

			dispatch(onboardingReducerActions.setData(data))
			dispatch(onboardingReducerActions.setStatus('fulfilled'))
		} catch (error: any) {
			if ((error as AxiosError)?.response?.status === 404) {
				dispatch(onboardingReducerActions.setStatus('fulfilled'))
			} else {
				console.error(error)
				dispatch(onboardingReducerActions.setStatus('rejected'))
			}
		}
	},

	complete:
		({ id }: { id: string }): TAction<Promise<void>> =>
		async (dispatch) => {
			try {
				await api.app.completeOnboarding({ id })

				dispatch(onboardingReducerActions.setData(null))
				dispatch(onboardingReducerActions.setStatus('fulfilled'))
			} catch (error: any) {
				//
			}
		},
}
