import { combineReducers, configureStore } from '@reduxjs/toolkit'

import * as bankAccount from './bankAccount'
import * as document from './document'
import * as loan from './loan'
import { onboardingReducer } from './onboarding'
import * as productsShowcase from './productShowcase'
import { profileReducer } from './profile'
import { requisitesReducer } from './requisites'
import * as saving from './saving'
import * as shares from './shares'
import { supportReducer } from './support'

const rootReducer = combineReducers({
	profile: profileReducer,
	onboarding: onboardingReducer,
	support: supportReducer,
	document: combineReducers({
		archive: document.archiveReducer,
		signing: document.signingReducer,
		pagination: document.paginationReducer,
	}),
	bankAccount: combineReducers({
		list: bankAccount.listReducer,
		single: bankAccount.singleReducer,
	}),
	requisites: requisitesReducer,
	loan: combineReducers({
		list: loan.listReducer,
		single: loan.singleReducer,
	}),
	shares: combineReducers({
		list: shares.listReducer,
		single: shares.singleReducer,
	}),
	productsShowcase: combineReducers({
		products: productsShowcase.productsReducer,
	}),
	saving: combineReducers({
		steps: saving.stepsReducer,
		list: saving.listReducer,
		single: saving.singleReducer,
	}),
})

const store = configureStore({
	reducer: rootReducer,
})

export { store }
