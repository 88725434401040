import { DefaultValues } from 'helpers/defaultsUtils'

export const partialWithdrawal = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		header: model.string(['header']),
		minAmount: model.null<number>(['minAmount']),
		availableForWithdrawalAmount: model.null<number>(['availableForWithdrawalAmount']),
	}
}
