import { list } from './list'
import { single } from './single'

export const shares = {
	list,
	single,
}

export namespace SharesDto {
	export type List = ReturnType<typeof list>
	export type Single = ReturnType<typeof single>
}
