import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { SharesDto } from 'converters/shares'
import { TUtils } from 'types'

import { commonReducers } from '../commonReducers'

interface State {
	data: Partial<Record<string, SharesDto.Single>>
	loadingId: TUtils.Maybe<string>
}

const initialState: State = {
	data: {},
	loadingId: null,
}

const singleSlice = createSlice({
	name: 'shares/single',
	initialState,

	reducers: {
		setData: (state, { payload }: PayloadAction<{ key: string; value: SharesDto.Single }>) => {
			state.data[payload.key] = payload.value
		},

		setLoadingId: (state, { payload }: PayloadAction<State['loadingId']>) => {
			state.loadingId = payload
		},

		reset: commonReducers.reset(initialState),
	},
})

const { actions: sharesSingleReducerActions, reducer } = singleSlice

export { sharesSingleReducerActions, reducer as singleReducer }
