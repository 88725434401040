import { normalize, schema } from 'normalizr'
import { head, last, sort } from 'ramda'

import { date } from '@creditclubteam/helpers'
import { DefaultValues } from 'helpers/defaultsUtils'

import { moneyMovement } from './moneyMovement'

export const single = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const [start, end] = [
		head(model.array(['moneyMovements'])),
		last(model.array(['moneyMovements'])),
	].map((v) => v && moneyMovement(v))

	const preservedSortType: (a: string, b: string) => number =
		start && end
			? new Date(end.date).valueOf() - new Date(start.date).valueOf() > 0
				? (a, b) => new Date(a).valueOf() + new Date(b).valueOf()
				: (a, b) => new Date(b).valueOf() - new Date(a).valueOf()
			: () => 0

	const {
		entities: { moneyMovements = [] },
	} = normalize(model.array(['moneyMovements']), [
		new schema.Entity('moneyMovements', undefined, {
			idAttribute: ({ date: value }) => date.format(new Date(value), { to: 'yyyy-MM-dd' }),
			mergeStrategy: (a, b) => [].concat(a, b).sort(preservedSortType),
			processStrategy: (value) => [moneyMovement(value)],
		}),
	])

	return {
		id: model.string(['id']),
		balance: model.null<number>(['balance']),
		moneyMovements: sort(([a], [b]) => preservedSortType(a, b), Object.entries(moneyMovements)),
	}
}
